<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="skill.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
          </div>
          
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('skill.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('skill.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>
      
        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('skill.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('skill.field.name')"
                    data-vv-name="skill.name"
                    data-vv-delay="500"
                    v-model="skill.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    trim
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'skill.name')">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('skill.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="skill.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="skill.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
                    
            <b-col v-if="isTagVisible" cols="12" md="8" class="pr-0">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="skill.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
        
        <label class="text-n-line" :class="state.autoScheduleExpanded ? null : 'collapsed'"
          :aria-expanded="state.autoScheduleExpanded ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="state.autoScheduleExpanded = !state.autoScheduleExpanded">
          <span>{{ $t('skill.auto_scheduling_title') }}
            <font-awesome-icon :icon="['far', 'chevron-up']" class="collapsable-icon" v-if="state.autoScheduleExpanded"/>
            <font-awesome-icon :icon="['far', 'chevron-down']" class="collapsable-icon" v-else />
          </span>
          
        </label>
        
        <b-collapse id="collapse-4" v-model="state.autoScheduleExpanded" class="mt-2">
          <p>{{ $t('skill.auto_scheduling_detail.line1') }}</p>
          <p>{{ $t('skill.auto_scheduling_detail.line2') }}</p>

          <template v-for="(item, index) in skill.skillLevels">
            <b-form-group :label="$t('skill.field.skillTypeLevel', [item.kind])" :label-for="`sLevel_${index}`" :key="index">
              <b-input-group>
                <b-form-input :id="`sLevel_${index}`" type="number" :max="365" :min="1"
                  v-model="item.data"
                  @blur="onLevelBlur(index)"
                  :readonly="isReadOnly">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </template>
        </b-collapse>
        

        <b-form-group v-if="exists && canView('NOTE') && canView(permissionName, ['NOTE'])">
          <NoteList :readOnly="isReadOnly || !canEdit(permissionName, ['NOTE'])" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
        </b-form-group>

        <b-row v-if="customFieldMap['notes'] != null">
          <template v-if="customFieldMap['notes'] != null">
            <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="skill[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>

      </template>
      
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="viewStaff" style="margin-right: auto" v-if="exists && canView('STAFF')">
          {{ $t('button.view_staff') }}
        </b-button>
        <b-button v-if="!isAccessDenied && ((exists && canEdit()) || (!exists && canAdd()))" :disabled="disableOk" size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="SKILL" :customFields="customFields" links="NOTE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
      <!-- staff selector -->
      <StaffSelectorModalForAdmin v-if="showStaffSelector"
        :show.sync="showStaffSelector" 
        :skill="skill"
        nonAdmin
        hideOkBtn
      />
    </template>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { strRandom, processRegExp } from '@/helpers';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { skillService,
         skillLinkTagService, tagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'SkillModal',
  components: {
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `SKILL_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'SKILL',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      showStaffSelector: false,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false,
        historyShow:         false,
        noteShow:            false
      },
      skill: {
        uuId:               null,
        name:               null,
        skillLevels:        [],
        identifier:         null,
        color:              null,
        readOnly:           false
      },
      tags: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      updatedColor: null,
      isAccessDenied: false,
      skillLevelEnum: [],
      skillLevelEnumObj: {},
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originSkill = null;
    this.originTags = [];
    this.originNotes = [];
    this.processWhenShowStateChanged(this.show);
  },
  mounted() {
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originSkill = null;
    this.originTags = null;
    this.originNotes = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `SKILL_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.skill.readOnly || this.$store.state.epoch.value !== null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'skill.name');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    labelTitle() {
      return this.title? this.title : this.$t('skill.title_new');
    },
    exists() {
      return this.id && !this.id.startsWith('SKILL_NEW_');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    disableOk() {
      return (this.original.readOnly && this.skill.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowStateChanged(newValue);
      }
    }
  },
  methods: {
    async getSkillLevelEnum() {
      await this.$store.dispatch('data/enumList').then(response => {
        if (response != null) {
          if (response.jobCase != null && response[response.jobCase] != null) {
            const propertyList = response[response.jobCase]
            const obj = propertyList.SkillLevelEnum
            
            const codes = Object.keys(obj)
            const list = []
            for (const c of codes) {
              if (obj[c] > -1) {
                this.skillLevelEnumObj[obj[c]] = c; // map the codes to strings for history
                list.push(c)
              }
            }
            this.skillLevelEnum.splice(0, this.skillLevelEnum.length, ...list)
          }
        }
      }).catch(e => {
        this.httpAjaxError(e)
      })
    },
    async processWhenShowStateChanged(newValue) {
      await getCustomFieldInfo(this, 'SKILL');
      this.$validator.resume();
      this.state.modalShow = newValue;
      this.state.autoScheduleExpanded = false;
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.notes = [];
      this.isAccessDenied = false;
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.restrictedRequiredField = null;
      this.resetSkillProperties();
      if (this.customFields.length == 0) {
        this.customFieldMap = {};
      } else {
        this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
      }
      await this.getSkillLevelEnum()
      if(newValue && this.id.indexOf('SKILL_NEW_') === -1) {
        this.skillGet(this.id);
      } else {
        if (newValue) { 
          const requiredFields = ['name']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        } else {
          this.isAccessDenied = false;
          if (!Array.isArray(this.skill.skillLevels)) {
            this.skill.skillLevels = []
          }
          const skillLevels = this.skill.skillLevels;
          skillLevels.splice(0, skillLevels.length);
          for (const level of this.skillLevelEnum) {
            skillLevels.push({ kind: level, data: 5 })
          }
        }
      }
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`skill.field.${val}`);
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "SKILL"}).then(value => {
        this.modelInfo = value.SKILL.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    skillGet(id) {
      skillService.query([{ uuId: id}], ['TAG', 'NOTE']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          const redactedProperties = data.redacted != null && data.redacted.length > 0? data.redacted : [];
          this.checkRedactedProps(redactedProperties);
          if (this.isAccessDenied) {
            return;
          }
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
        } else {
          this.httpAjaxError(e);
        }
      });
    },
    digestResponse(data) {
      const s = this.skill;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }

      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      if (!Array.isArray(s.skillLevels)) {
        s.skillLevels = []
      }
      this.originSkill = cloneDeep(s);
      const sLevels = []
      for (const level of this.skillLevelEnum) {
        const found = s.skillLevels.find(i => i.kind == level)
        let data = found != null? found.data : 5
        if (data == null || isNaN(data)) {
          data = 5
        }

        if (typeof data != 'number') {
          data = parseInt(data)
        }

        if (data  < 1) {
          data = 1
        } else if (data > 365) {
          data = 365
        }
        sLevels.push({ kind: level, data })
      }
      s.skillLevels = sLevels
      
       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        list.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      } else {
        this.originTags.splice(0, this.originTags.length);
        this.tags.splice(0, this.tags.length);
      }
      
    },
    modalOk() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.skill[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.count() < 1) {
          this.skillSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    skillSubmit() {
      const data = cloneDeep(this.skill);
      
      if(this.id.indexOf('SKILL_NEW_') !== -1) {
        delete data['uuId'];
        this.skillPost('create', data);
      } else {
        this.skillPost('update', data);
      }
    },
    async skillPost(method, data) {
      this.state.isSubmitting = true;
      //Skip updating skill if there is no change in skill properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedSkillProperties(data);
      }
      
      let result = null;
      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        result = await skillService[method]([data])
        .then((response) => {
          let uuId = null;
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            uuId = feedbackList[0].uuId
          }
          return { uuId: uuId };
        })
        .catch(e => {
           return { hasError: true, error: e }
        });

        if (result != null && (result.hasError || result.uuId == null)) {
          this.httpAjaxError(result.error);
          this.state.isSubmitting = false;
          return;
        }
        
        if (method === 'create') {
          data.uuId = result.uuId;
        }
      }

      let hasError = false;

      // save the color in the profile
      this.updatedColor = data.color;
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(data.uuId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`skill.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(data.uuId, skillLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`skill.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`skill.${method}`) });
      }

      this.state.isSubmitting = false;
    },
    httpAjaxError(e) {
      const response = e != null? e.response: null;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `skill.${feedback.args[0]}`;
          const args = [this.$t(`skill.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
            case 'cannot_be_blank':
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    async updateTags(skillId) {
      const result = {
        hasError: false
      }
      const tags = cloneDeep(this.tags);
      const originTags = this.originTags;

      // If any tag has been removed from the list, delete the link
      let toDelete = [];
      for (let i = 0; i < originTags.length; i++) {
        if (!(tags.map(s => s.uuId).includes(originTags[i].uuId))) {
          toDelete.push({uuId: originTags[i].uuId});
        }
      }
      if (toDelete.length > 0) {
        await skillLinkTagService.remove(skillId, toDelete);
      }

      // If any tag has been added to the list, create the link
      let toAdd = [];
      for (let i = 0; i < tags.length; i++) {
        if (!tags[i].uuId) {
          // get the uuId
          tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
            if (response.data.length !== 0) {
              return response.data[0].uuId;
            }
            return null;
          });
          
          if (tags[i].uuId === null) {
            tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
              if (response.data[response.data.jobCase].length !== 0) {
                return response.data[response.data.jobCase][0].uuId;
              }
              return null;
            });
          }
        }
        
        if (tags[i].uuId !== null &&
            !(originTags.map(s => s.uuId).includes(tags[i].uuId))) {
          toAdd.push({uuId: tags[i].uuId});
        }
      }
      if (toAdd.length > 0) {
        await skillLinkTagService.create(skillId, toAdd);
      }

      return result;
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetSkillProperties() {
      const keys = Object.keys(this.skill);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        } else if (keys[i] === 'skillLevels') {
          this.skill.skillLevels = this.skillLevelEnum.map(i => { return { kind: i, data: 5 }})
        } else {
          let customField = this.customFields.find(f => f.name === keys[i])
          if (customField) {
            if (customField.def) {
              this.skill[keys[i]] = customField.def;
              continue;
            }
          }
          this.skill[keys[i]] = null;
        }
      }
      this.originSkill = null;
      this.originNotes = [];
      this.notes = [];
    },
    removeUnchangedSkillProperties(data) {
      //Remove those properties whose value is not changed in provided data against original skill.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalSkill = this.originSkill;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalSkill[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    checkRedactedProps(redactedProperties) {
      if (redactedProperties.length > 0) {
        const mandatoryProps = [
            'SKILL.name'
        ]
        for (const rProp of redactedProperties) {
          if (mandatoryProps.includes(rProp)) {
            this.isAccessDenied = true;
            break;
          }
        }
      }
    },
    onLevelBlur(index) {
      const skillLevels = this.skill.skillLevels
      if (skillLevels[index].data == null || isNaN(skillLevels[index].data)) {
        skillLevels[index].data = 5
      } else if (skillLevels[index].data < 1) {
        skillLevels[index].data = 1
      } else if (skillLevels[index].data > 365) {
        skillLevels[index].data = 365
      }
    },
    viewStaff() {
      this.showStaffSelector = true;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>
